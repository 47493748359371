import React, { useContext } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { get } from 'lodash';
import { handleLink } from '../helpers/general';
import CartContext from '../context/CartProvider';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Seo from "../components/organisms/Seo/Seo"
import Cart from '../components/organisms/Cart/Cart';
import CartSummary from '../components/molecules/CartSummary/CartSummary';
import * as styles from './cartpage.module.css';
import ContentfulTranslationText from '../components/atoms/ContentfulTranslationText/ContentfulTranslationText';

const CartIndexPage = () => {
  const ctx = useContext(CartContext);
  const numberItems = get(ctx, 'state.cart.numberItems');

  return (
    <Layout>
      <Seo title="Cart" />
      <Container size="large">
        {!numberItems && (
          <div className={styles.bcCartContainer}>
            <div className={styles.bcCartTitle}>
              <h6 className="text-left">
                <ContentfulTranslationText keyName="cart">
                  Cart
                </ContentfulTranslationText>
              </h6>
              <Link to={handleLink("/shop/")} className={styles.continueShopping}>
                <ContentfulTranslationText keyName="continueShopping">
                  continue shopping
                </ContentfulTranslationText>
              </Link>
            </div>
            <div className={styles.bcCartEmpty}>
              <div className="text-center">
                <p className="subtitle text-center">
                  <ContentfulTranslationText keyName="emptyCart">
                    Your cart is empty.
                  </ContentfulTranslationText>
                </p>
                <Link to={handleLink("/shop/")}>
                  <ContentfulTranslationText keyName="lookAround">
                    Take a look around.
                  </ContentfulTranslationText>
                </Link>
              </div>
            </div>
          </div>
        )}
        {!!numberItems && (
          <div className={clsx('grid-gap60 grid-7030', styles.bcCartContainer)}>
            <div>
              <div>
                <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {link: '/cart', label:'Cart'}]} />
              </div>
              <div className={styles.bcCartTitle}>
                <h6 className="text-left">Cart</h6>
                <div className={styles.textBanner}>
                  <span>EST. DELIVERY: </span>
                  <span>1 FEB 2022 </span>
                </div>
                {/* <Link to={handleLink("/shop/")} className={styles.continueShopping}>
                  continue shopping
                </Link> */}
              </div>
              <Cart cartType="full" />
            </div>
            <CartSummary cartType="full" />
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default CartIndexPage;
